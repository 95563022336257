export const NOTIFICATION_VARIANTS = {
  SUCCESS: "SUCCESS",
  ERROR: "ERROR",
  WARNING: "WARNING",
};

export const SLIDE_OVER = {
  COMPANY: "COMPANY",
  LOAN: "LOAN",
  USER: "USER",
  NEED_LIST: "NEED_LIST",
  NEED_LIST_FILE: "NEED_LIST_FILE",
  MY_NEED_LIST_FILE: "MY_NEED_LIST_FILE",
  LOAN_TYPE: "LOAN_TYPE",
  LOAN_STATUS: "LOAN_STATUS",
  PROPERTY_TYPE: "PROPERTY_TYPE",
  PROPERTY_USE: "PROPERTY_USE",
  VALUE_BASED_ON: "VALUE_BASED_ON",
  DOCUMENT: "DOCUMENT",
  LENDER_CALL_LOG_STATUS: "LENDER_CALL_LOG_STATUS",
  LENDING_COMPANY: "LENDING_COMPANY",
  LENDER_CALL_LOG: "LENDER_CALL_LOG",
  LOAN_CATEGORY_FILTER: "LOAN_CATEGORY_FILTER",
  CONTACTS_CATEGORY_FILTER: "CONTACTS_CATEGORY_FILTER",
  LENDING_COMPANY_CATEGORY_FILTER: "LENDING_COMPANY_CATEGORY_FILTER",
  LOAN_SUMMARY_LIST: "LOAN_SUMMARY_LIST",
  EMPLOYEE_ROLES_FILTER: "EMPLOYEE_ROLES_FILTER",
  EMPLOYEE_FORM: "EMPLOYEE_FORM",
  NEEDS_LIST_TEMPLATE_FORM: "NEEDS_LIST_TEMPLATE_FORM",
  CLONE_NEEDS_LIST_TEMPLATE_FORM: "CLONE_NEEDS_LIST_TEMPLATE_FORM",
  NEEDS_LIST_ADD_FROM_TEMPLATE: "NEEDS_LIST_ADD_FROM_TEMPLATE",
  CREATE_NEEDS_LIST_TEMPLATE_FROM_BORROWER_NEEDS_LIST: "CREATE_NEEDS_LIST_TEMPLATE_FROM_BORROWER_NEEDS_LIST",
  LOAN_COLLABORATORS: "LOAN_COLLABORATORS",
  CREATABLE_ONLY_CONTACT_FORM: "CREATABLE_ONLY_CONTACT_FORM",
  BROKER_BORROWER_NEEDS_LIST_FILE_FORM: "BROKER_BORROWER_NEEDS_LIST_FILE_FORM",
  BORROWER_NEEDS_LIST_FILE_FORM: "BORROWER_NEEDS_LIST_FILE_FORM",
  LENDERS_HEAT_MAP: "LENDERS_HEAT_MAP",
};

export const MODAL = {
  USER: "USER",
  LENDING_STATES: "LENDING_STATES",
  LENDING_LOAN_TYPES: "LENDING_LOAN_TYPES",
  LENDING_PROPERTY_TYPES: "LENDING_PROPERTY_TYPES",
  LENDING_LOAN_PROGRAMS: "LENDING_LOAN_PROGRAMS",
  VALIDATE_VERIFICATION_CODE: "VALIDATE_VERIFICATION_CODE",
  LENDING_PARAMETERS_WRAPPER: "LENDING_PARAMETERS_WRAPPER",
  CONFIRMATION: "CONFIRMATION",
  NEED_LIST_FILES: "NEED_LIST_FILES",
  MY_NEED_LIST_FILES: "MY_NEED_LIST_FILES",
  LENDING_COMPANY_OVERVIEW: "LENDING_COMPANY_OVERVIEW",
  USER_OVERVIEW: "USER_OVERVIEW",
  LENDER_RECORD_LIST: "LENDER_RECORD_LIST",
  LOAN_SUMMARY_WRAPPER: "LOAN_SUMMARY_WRAPPER",
  LENDER_LOAN_SUMMARY_RESPONSE_FORM: "LENDER_LOAN_SUMMARY_RESPONSE_FORM",
  CONFIRMATION_V2: "CONFIRMATION_V2",
  NEEDS_LIST_TEMPLATE_DOCUMENTS: "NEEDS_LIST_TEMPLATE_DOCUMENTS",
  LENDER_CALL_LOG_NOTES: "LENDER_CALL_LOG_NOTES",
  CREATABLE_ONLY_CONTACT_FORM: "CREATABLE_ONLY_CONTACT_FORM",
  BROKER_BORROWER_NEEDS_LIST_FILES: "BROKER_BORROWER_NEEDS_LIST_FILES",
  BORROWER_NEEDS_LIST_FILES: "BORROWER_NEEDS_LIST_FILES",
  LENDER_LOAN_SUMMARY_NOTIFICATIONS: "LENDER_LOAN_SUMMARY_NOTIFICATIONS",
  LENDER_CRITERIA_FORM_WRAPPER: "LENDER_CRITERIA_FORM_WRAPPER",
  LENDER_OFFER_FORM_WRAPPER: "LENDER_OFFER_FORM_WRAPPER",
  BORROWER_APPLICATION_PARAMETERS: "BORROWER_APPLICATION_PARAMETERS",
};

export const NEED_LIST_FILE_CONFIRMATIONS = {
  DELETE: "DELETE",
  APPROVE: "APPROVE",
  REJECT: "REJECT",
};

export const SORT_OPTIONS = [
  {
    id: 1,
    name: "Date: Oldest to Newest",
  },
  {
    id: 2,
    name: "Date: Newest to Oldest",
  },
  {
    id: 3,
    name: "Name: A to Z",
  },
  {
    id: 4,
    name: "Name: Z to A",
  },
  {
    id: 5,
    name: "Amount: Lowest to Highest",
  },
  {
    id: 6,
    name: "Amount: Highest to Lowest",
  },
  {
    id: 7,
    name: "Popularity",
  },
];

export const CONTACT_SORT_OPTIONS = [
  {
    id: 1,
    name: "Date: Oldest to Newest",
  },
  {
    id: 2,
    name: "Date: Newest to Oldest",
  },
  {
    id: 3,
    name: "Name: A to Z",
  },
  {
    id: 4,
    name: "Name: Z to A",
  },
  {
    id: 5,
    name: "Company: A to Z",
  },
  {
    id: 6,
    name: "Company: Z to A",
  },
];

export const TEMPLATE_DOCUMENTS_SORT_OPTIONS = [
  {
    id: 1,
    name: "Date: Oldest to Newest",
  },
  {
    id: 2,
    name: "Date: Newest to Oldest",
  },
  {
    id: 3,
    name: "Name: A to Z",
  },
  {
    id: 4,
    name: "Name: Z to A",
  },
  {
    id: 5,
    name: "Status: Inactive to Active",
  },
  {
    id: 6,
    name: "Status: Active to Inactive",
  },
];

export const LENDER_CALL_LOG_SORT_OPTIONS = [
  {
    id: 1,
    name: "Date: Oldest to Newest",
  },
  {
    id: 2,
    name: "Date: Newest to Oldest",
  },
  {
    id: 3,
    name: "Last Interaction: Oldest to Newest",
  },
  {
    id: 4,
    name: "Last Interaction: Newest to Oldest",
  },
  {
    id: 5,
    name: "Lender: A to Z",
  },
  {
    id: 6,
    name: "Lender: Z to A",
  },
  {
    id: 7,
    name: "Company: A to Z",
  },
  {
    id: 8,
    name: "Company: Z to A",
  },
  {
    id: 9,
    name: "Status: A to Z",
  },
  {
    id: 10,
    name: "Status: Z to A",
  },
  {
    id: 11,
    name: "Matching: Lowest to Highest",
  },
  {
    id: 12,
    name: "Matching: Highest to Lowest",
  },
];

export const LENDERS_HEAT_MAP = [
  {
    id: 1,
    name: "Matching: Lowest to Highest",
  },
  {
    id: 2,
    name: "Matching: Highest to Lowest",
  },
  {
    id: 3,
    name: "Last Update: Oldest to Newest",
  },
  {
    id: 4,
    name: "Last Update: Newest to Oldest",
  },
  {
    id: 5,
    name: "Lender: A to Z",
  },
  {
    id: 6,
    name: "Lender: Z to A",
  },
  {
    id: 7,
    name: "Company: A to Z",
  },
  {
    id: 8,
    name: "Company: Z to A",
  },
];

export const DARK_MODE_COOKIE_NAME = "darkMode";

export const ROLES_LEVEL = {
  OWN: {
    CODE: "OWN",
    DESCRIPTION: "Owner",
  },
  ADM: {
    CODE: "ADM",
    DESCRIPTION: "Broker",
  },
  BRO: {
    CODE: "BRO",
    DESCRIPTION: "Processor",
  },
  AFF: {
    CODE: "AFF",
    DESCRIPTION: "Affiliate",
  },
};

// TODO: Update routes to comply good practices
export const PLAIN_LAYOUT_PAGE = /(\/$|finance-worksheet)/g;

export const BLANK_LAYOUT_PAGES =
  /^\/(404|500|400|forbidden|my-needs-list|expired|policy|blog\/google-api-disclosure)|summary|summaries/g;

export const DEFAULT_NOTIFICATION_PAYLOAD = {
  message: {
    title: "Saved",
    description: "",
    options: {
      variant: NOTIFICATION_VARIANTS.SUCCESS,
    },
  },
};

export const HEADERS = {
  TENANT_ID: "X-Fundview-Tenant-Id",
  APP_ID: "X-Fundview-App-Id",
  VERSION_ID: "X-Fundview-Version-Id",
};

export const DEFAULT_SLIDER_CONFIGURATION = {
  MIN: 0,
  MAX: 100,
  STEP: 1,
  MIN_DISTANCE: 1,
};

export const CONTACT_CONNECTORS = {
  GOOGLE: "GOOG",
  MICROSOFT: "MSFT",
};

export const DEFAULT_MAX_SCREEN_SIZES = {
  SM: 1024,
};

export const GOOGLE_CONTACTS_CONFIGURATION = {
  ENDPOINT: "https://www.googleapis.com/discovery/v1/apis/people/v1/rest",
  SCOPE: "https://www.googleapis.com/auth/contacts.readonly",
  FIELDS: "names,emailAddresses,phoneNumbers",
  RESOURCE_NAME: "people/me",
  PAGE_SIZE: 300,
};

export const MIME_TYPES_SUPPORTED = [
  "image/png",
  "image/jpeg",
  "application/pdf",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/rtf",
  "text/plain",
];

export const MAX_NEEDS_LIST_FILES_UPLOAD = 5;

export const LENDING_PARAMETER_GUARANTEES = {
  REC: "Recourse",
  NRE: "Non-Recourse",
  RNR: "Recourse / Non-Recourse",
};

export const RECAPTCHA_ACTION_NAMES = {
  DEMO_FORM: "demo_form",
};

export const LENDER_LOAN_SUMMARY_STATUSES = {
  OPE: {
    ID: "OPE",
    DESCRIPTION: "Pending",
  },
  INT: {
    ID: "INT",
    DESCRIPTION: "Interested",
  },
  NIN: {
    ID: "NIN",
    DESCRIPTION: "Passed",
  },
};

export const LOAN_SUMMARY_NOTIFICATION_EXAMPLE =
  "https://fvsiteprodst01.blob.core.windows.net/images/loan_summary_notification_example.png";

export const EMPTY_GUID = "00000000-0000-0000-0000-000000000000";
