import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isProcessing: false,
  parameterId: null,
};

export const lenderCriteriaParameterSlice = createSlice({
  name: "lenderCriteriaParameter",
  initialState,
  reducers: {
    setLenderCriteriaParameterOnProcessingState(state, action) {
      return { ...state, isProcessing: true, parameterId: action.payload.parameterId };
    },
    clearLenderCriteriaParameterOnProcessingState(state) {
      return { ...state, isProcessing: false, parameterId: null };
    },
  },
});

export const { setLenderCriteriaParameterOnProcessingState, clearLenderCriteriaParameterOnProcessingState } =
  lenderCriteriaParameterSlice.actions;

export default lenderCriteriaParameterSlice.reducer;
