import dynamic from "next/dynamic";

export const MODAL_COLLECTION = {
  USER: dynamic(() => import("../contact/forms/contact_form"), { ssr: false }),
  LENDING_STATES: dynamic(() => import("../lending_parameters/lending_states"), { ssr: false }),
  LENDING_LOAN_TYPES: dynamic(() => import("../lending_parameters/lending_loan_types"), { ssr: false }),
  LENDING_PROPERTY_TYPES: dynamic(() => import("../lending_parameters/lending_property_types"), { ssr: false }),
  LENDING_LOAN_PROGRAMS: dynamic(() => import("../lending_parameters/lending_loan_programs"), { ssr: false }),
  LENDING_PARAMETERS_WRAPPER: dynamic(() => import("../lending_parameters/lending_parameters_wrapper"), { ssr: false }),
  CONFIRMATION: dynamic(() => import("./modal_confirmation"), { ssr: false }),
  NEED_LIST_FILES: dynamic(() => import("../needs_list/need_list_file_record_list"), { ssr: false }),
  MY_NEED_LIST_FILES: dynamic(() => import("../needs_list/my_need_list_file_record_list"), { ssr: false }),
  LENDING_COMPANY_OVERVIEW: dynamic(() => import("../forms/lending_company_form/overview"), { ssr: false }),
  USER_OVERVIEW: dynamic(() => import("../contact/forms/contact_view"), { ssr: false }),
  LENDER_RECORD_LIST: dynamic(() => import("../lending_company/lender_record_list"), { ssr: false }),
  LOAN_SUMMARY_WRAPPER: dynamic(() => import("../loan_summary/form/loan_summary_wrapper"), { ssr: false }),
  LENDER_LOAN_SUMMARY_RESPONSE_FORM: dynamic(() => import("../loan_summary/form/loan_summary_response_form"), {
    ssr: false,
  }),
  CONFIRMATION_V2: dynamic(() => import("./modal_confirmation_v2"), { ssr: false }),
  NEEDS_LIST_TEMPLATE_DOCUMENTS: dynamic(() => import("../needs_list_templates/lists/needs_list_template_documents"), {
    ssr: false,
  }),
  LENDER_CALL_LOG_NOTES: dynamic(() => import("../lenders_call_log/list/lender_call_log_note_list"), { ssr: false }),
  CREATABLE_ONLY_CONTACT_FORM: dynamic(() => import("../contact/forms/creatable_contact_form"), { ssr: false }),
  BROKER_BORROWER_NEEDS_LIST_FILES: dynamic(() => import("../loan/broker/broker_needs_list_files"), {
    ssr: false,
  }),
  BORROWER_NEEDS_LIST_FILES: dynamic(() => import("../loan/borrower/borrower_needs_list_files"), {
    ssr: false,
  }),
  LENDER_LOAN_SUMMARY_NOTIFICATIONS: dynamic(
    () => import("../lenders_call_log/controls/lender_loan_summary_notifications"),
    {
      ssr: false,
    },
  ),
  LENDER_CRITERIA_FORM_WRAPPER: dynamic(() => import("../lender_criteria/lender_criteria_form_wrapper"), {
    ssr: false,
  }),
  LENDER_OFFER_FORM_WRAPPER: dynamic(() => import("../lender_offer/lender_offer_form_wrapper"), {
    ssr: false,
  }),
  BORROWER_APPLICATION_PARAMETERS: dynamic(() => import("../loan/loan_form/borrower_application_parameters_form"), {
    ssr: false,
  }),
};

export const MODAL_SIZES = {
  SM: "SM",
  MD: "MD",
  LG: "LG",
  XL: "XL",
};
